


import earth from './Earth.png';

function Earth(){
    return <img className = 'earth' src = {earth} />;
}


export default Earth