import link from './linkedin.png';
import './image.css';


function LinkedIn(){
    return(

        <a href = {"https://www.linkedin.com/in/seojin-park-332aaa213/"} target="_blank">
            <img className = 'linkicon' src = {link} />

        </a>
        

    );
    
    
}

export default LinkedIn